import logo from './assets/animated_sound.gif';
import './App.css';
import {useState} from 'react'

function App() {

  const names = [    
    "My Endogenous Gravy",
    "Frightened Turtles",
    "Minnesota Hotdish",
    "NY Cat Skills",
    "Textured Water",
    "Howard's Apparatus",
    "Goat Butter",
    "Oboe Wan Kenobi",
    "Dull Eddie and The Sharpshooters",
    "Night Soil",
    "COVID-1999",
    "Lobster Face",
    "Jumpsuit Elvis",
    "Biscuit Politico",
    "Counterfeit Fiat",
    "Haha Aloha",
    "Switchboard Surfers",
    "A Seagull Named Seamus",
    "Pickleback",
    "Porcelain Plums",
    "McScruffles",
    "Sausage Tooth",
    "Trickle Down",
    "Water Cats",
    "Peach Stubble",
    "The Sticky Eyelids",
    "Jimmy Dean's Dream",
    "Fast Food Masterpiece",
    "Middle School Hearthrobs",
    "Soft Blood",
    "Bitter Beans",
    "Pistols for Pagers",
    "Creative Accountants",
    "Easter Doughnuts",
    "Careless Monks",
    "Stacks of Snacks",
    "Back Alley Chiropractors",
    "Membrane Legislature",
    "The Naughty Sprockets",
    "Lung Farm",
    "Orifice Tassel",    
    "Pigeon Savant",
    "Designer Babies",
    "Marmalade Mamma",
    "Thunder Crib",
    "Soapy Throne",
    "The Smut Peddlers",
    "Otter Slaughter",
    "Stronger than Paint",
    "Toe Beans",
    "Babykaboomers",
    "Bourgeois Marxists",
    "Mathy Girls",
    "Beauty School Astrologists",
    "Uber for Cats",
    "Suspiciously Soft",
    "Unexpected Texture",
    "Mammal Cluster",
    "Animals with Backbones",
    "Filthy Martini",
    "Slather",
    "Too Many Legs",
    "John Dough",
    "Mouthfeel",
    "St. Catherine's Home for Lazy Kittens"
  ]

  const [availableNames, setAvailableNames] = useState([...names])
  const [bandName, setBandName] = useState("");

  function generateName() {
    if(availableNames.length === 0) {
      setAvailableNames([...names])      
    }
    const index = Math.floor(Math.random() * availableNames.length)
    const thisName = availableNames[index]
    console.log(index, thisName)
    setAvailableNames(names => (names.filter((_, i) => i !== index)))
    setBandName(thisName)
  }

  return (
    <div className="App">
      <header className="App-header">
        <div style={{margin: 20}}><h2>My next band name is...</h2></div>
        <div 
          style={{
            display: 'flex',  
            margin: 20, 
            minHeight: 55,
            alignItems: 'center',
            justifyContent: 'center'
          }}>
            <h2>{bandName}</h2>
          </div>
        <div onClick={generateName} style={{margin: 20}}>
          <img src={logo} className="App-logo" alt="logo" onClick={generateName}/>        
        </div>
      </header>
    </div>
  );
}

export default App;
